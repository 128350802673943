<template>
  <form class="divide-y divide-gray-200" @submit.prevent="update">
    <div class="py-6 px-4 sm:p-6 lg:pb-8">
      <div>
        <h2 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("Manage initial values") }}
        </h2>
        <p class="mt-1 text-sm text-gray-500">
          {{
            $t(
              "By specifying the bank and cash balances in euros, the budget book corresponds to your real financial situation."
            )
          }}
        </p>
      </div>

      <div class="mt-6 grid grid-cols-12 gap-6">
        <div class="col-span-12 sm:col-span-6">
          <div v-for="(item, i) in userPreference" :key="i" class="my-2">
            <label
              :for="item.id"
              class="block text-sm font-medium text-gray-700"
              >{{ item.name }}</label
            >
            <input
              type="text"
              :name="item.id"
              :id="item.id"
              v-model="form[item.id]"
              class="
                mt-1
                block
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-12 gap-6">
        <div class="col-span-12 sm:col-span-6">
          <button
            type="submit"
            class="
              w-full
              bg-secondary
              border border-transparent
              rounded-md
              shadow-sm
              py-2
              px-4
              inline-flex
              justify-center
              text-sm
              font-medium
              text-white
              hover:bg-fuchsia-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-fuchsia-500
            "
          >
            {{ $t("Save") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      form: new Object(),
    };
  },
  computed: {
    userPreference() {
      return this.$store.state.User.userPreference;
    },
  },
  methods: {
    update() {
      const data = [];
      Object.keys(this.form).forEach((item) => {
        if (item != "undefined") {
          data.push({
            preference_id: item,
            value: this.form[item],
          });
        }
      });
      this.$store.dispatch("User/UpdateUserPreference", data);
    },
  },
  mounted() {
    this.$store.dispatch("User/GetUserPreference").then(() => {
      this.userPreference.forEach((element) => {
        this.form[element.id] = element.value;
      });
    });
  },
};
</script>

<style></style>
